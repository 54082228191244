@keyframes Nd8Kjq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Nd8Kjq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Nd8Kjq_container {
  background: #34353f;
  border-radius: 16px;
  padding: 24px 24px 24px 16px;
  position: relative;
  overflow-y: auto;
}

.Nd8Kjq_container .Nd8Kjq_placeholder {
  color: #95969f;
  font: var(--font-body-2);
  position: absolute;
  inset: 24px 32px 24px 24px;
}

.Nd8Kjq_container .Nd8Kjq_copy {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  justify-content: right;
  align-items: center;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.Nd8Kjq_container:hover .Nd8Kjq_copy {
  opacity: 1;
}

.Nd8Kjq_container .Nd8Kjq_editor {
  position: relative;
  overflow-x: auto;
}

.Nd8Kjq_container .Nd8Kjq_editor textarea {
  width: 100%;
  white-space: pre;
  word-break: normal;
  resize: none;
  color: #fefefe;
  -webkit-text-fill-color: transparent;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.Nd8Kjq_container .Nd8Kjq_editor:not(.Nd8Kjq_readonly) textarea, .Nd8Kjq_container .Nd8Kjq_editor:not(.Nd8Kjq_readonly) pre {
  min-height: 80px;
  display: flex;
}

.Nd8Kjq_container .Nd8Kjq_editor::-webkit-scrollbar {
  display: none;
}

.Nd8Kjq_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes bHqZ2W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bHqZ2W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.bHqZ2W_basic {
  gap: 16px;
  display: flex;
}

.bHqZ2W_basic .bHqZ2W_item {
  margin: 0;
}

/*# sourceMappingURL=README.99eca6b9.css.map */
